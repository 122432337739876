<template>
  <div>
    <Sticky>
      <nav class="navbar navbar-horizontal navbar-expand-lg" style="background: radial-gradient(circle, #7b0e7f 0%, #431044 100%); border: none;">
        <div class="container-fluid">
          <div
            class="navbar-brand text-dark"
            :class="
              $route.path !== '/home' ? 'd-flex justify-content-between' : ''
            "
          >
            <div
              class="d-flex flex-column banking-icons my-auto ml-1 mr-3"
              v-if="$route.path !== '/auth/login'"
            >
              <i
                class="fas fa-chevron-left mx-auto my-1 text-xl text-muted"
                @click=" $router.go(-1)"
                v-if="$route.path !== '/home'"
              ></i>
            </div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/pg-boss-horizontal.png"
              alt=""
              @click=" $router.push('/home')"
              style="width: auto; height: 2.7rem;"
            />
            <!-- LOGO -->
          </div>
          <div class="d-flex">
            <div class="d-flex" v-if="$route.path !== '/auth/login' && authUser">
              
              <span class="my-auto pr-3" style="border-right: 1px solid gray;">
                <span class="text-sm text-uppercase" style="color: #ccc">
                  <img loading="lazy" class="coin" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/hulk333/coin.png" alt="">
                </span>
                <span class="text-white text-md">
                  <b>{{ Number($store.state.authUser.amount).toLocaleString() }}</b> MMK
                </span>
              </span>
            </div>
            
            <!-- <div class="nav-link px-1 pl-3 mx-0 d-flex justify-content-between">
              <a href="#" @click="showTopImage()" style="position: relative">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/luckywin/icons/notification%20(1).png" style="width: 25px;">
              </a>
            </div>

            <van-popup v-model="showTop" round position="top" :style="{ height: '40%' }">
              <h3 class="pl-3 py-3">Latest Messages</h3>
                <div v-for="(list, index) in messages" :key="index" class="mx-4 py-3" style="border-bottom: 1px solid #CFCFCF" v-show="messages.length > 0">
                  <div class="d-flex justify-content-between">
                    <h4>{{ list.title }}</h4><h4>{{ timeFormat(list.created_at) }}</h4>
                  </div>
                  <span class="text-sm">{{ list.message }}</span>
                </div>
                <van-empty description="No Notifications Yet!" v-show="messages.length === 0"/>
            </van-popup> -->

            <!-- <div class="nav-link px-1 mx-0 d-flex justify-content-between">
              <a href="https://t.me/hulk333vip">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/hulk333/icons/telegram%20(1).png" style="width: 25px;">
              </a>
            </div> -->

            <!-- Language -->
            <div
              class="nav-link d-flex justify-content-between px-1 pt-1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
                  <div id="navbar-language">
                    <img
                        :src="$t('images.lang-img')"
                        alt=""
                        class="mr-2"
                        style="width: 25px; height: 25px; border-radius: 50%;"
                      />
                  </div>
                </div>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbar-language"
                >
                  <a class="dropdown-item" @click="setLangLocalstorage('en')"
                    ><img
                      src="https://cdn.myanmarsoccer.xyz/build/assets/img/icons/common/united-kingdom.png"
                      alt=""
                      class="mr-2"
                      style="width: 20px; height: 20px"
                    />
                    {{$t("en")}}</a
                  >
                  <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="setLangLocalstorage('mm')"
                      ><img
                        src="https://cdn.myanmarsoccer.xyz/build/assets/img/icons/common/myanmar.png"
                        alt=""
                        class="mr-2"
                        style="width: 20px; height: 20px"
                      />{{$t('mm')}}</a
                    >
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="setLangLocalstorage('cn')"
                      ><img
                        src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/china.png"
                        alt=""
                        class="mr-2"
                        style="width: 20px; height: 20px"
                      />{{ $t("cn") }}</a
                    >
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="setLangLocalstorage('th')"
                      ><img
                        src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/thailand.png"
                        alt=""
                        class="mr-2"
                        style="width: 20px; height: 20px"
                      />{{ $t("th") }}</a
                    >
                  </div>
                  <!-- <div class="my-auto pr-2" v-if="alreadyGranted && authUser">
                    <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/b067eede-486d-4336-e303-968134d62f00/public" style="width: auto; height: 35px;" alt="" @click=" askNotificationPermission()">
                  </div> -->
            </div>
            
        </div>
      </nav>
    </Sticky>
  </div>
</template>
<script>
import { Toast, Dialog, Sticky } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Sticky },
  data() {
    return {
      messages: [],
      showTop: false,
      // imageLink: `https://ui-avatars.com/api/?name=${this.$store.state.authUser.email}&background=0D8ABC&color=fff`,
      isLogged: false,
      authUser: null,
      alreadyGranted: true,
    };
  },
  methods: {
    timeFormat(time) {
      return moment(time).startOf('hour').fromNow();
    },
    async getMessages() {
      try {
        const res = await axios.get('/messages');
        this.messages = res.data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    showTopImage() {
      this.showTop = !this.showTop;
    },
    ...mapActions(['logoutUser', 'fetchUser']),
    askNotificationPermission() {
        Notification.requestPermission().then(async (permission) => {
          if(permission === 'granted') {
            this.alreadyGranted = false;
           alert(
              this.$store.state.language === 'en'
                ? 'Notification permission granted!'
                : 'အသိပေးချက်အတည်ပြုပြီးပြီ'
            );
          } else {
            alert(
              this.$store.state.language === 'en'
                ? 'Notification permission denied!'
                : 'အသိပေးချက်အတည်ပြုမပြီးပြီ'
            );
          }
        });
  },
    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          if(this.$route.path === '/home') {
            this.$router.go();
          } else {
            this.$router.push('/home');
            this.$router.go();
          }
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
    closeNavCollapse() {
      $('#navbar-default').collapse('hide');
    },
    setLangLocalstorage(language) {
      localStorage.setItem('language', language);
      this.$store.commit('setLanguage', language);
      this.$i18n.locale = language
      $('#navbar-default').collapse('hide');
    },
    redirectLink(link) {
      this.$router.push(`/${link}`).catch(() => {});
      $('#navbar-default').collapse('hide');
    },
    redirectRoute(path) {
      if (path === '2d') {
        this.$router.push('/user/2d');
      } else {
        this.$router.push('/user/3d');
      }
      return true;
    },
  },
  async mounted() {
    // this.getMessages();
    // if(window.Notification.permission === 'granted') {
    //   this.alreadyGranted = false;
    // }

    this.authUser = await this.fetchUser();
    if (this.authUser) {
      this.isLogged = true;
    }
  }
};
</script>
<style scoped>
/* CSS */
.login-btn {
  background: transparent;
  border: 1px solid #D02126;
  border-radius: 3px;
  padding: .3rem 1.5rem;
  color: #D02126;
}

.coin {
    height: 1.3rem;
    margin-top: -0.25rem;
    margin-right: 0.4rem;
    animation: flip-coin-49989627 2.5s linear infinite;
    animation-delay: 0s;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    transform-style: preserve-3d; /* Keeps the 3D effect */
}

/* Define the keyframes for the 3D flip animation */
@keyframes flip-coin-49989627 {
    0% {
        transform: rotateY(0deg); /* Front face */
    }
    50% {
        transform: rotateY(180deg); /* Back face */
    }
    100% {
        transform: rotateY(360deg); /* Front face again */
    }
}

.navbar {
  padding: 3px;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  /* border-bottom: 2px solid black; */
}
.navbar-horizontal .navbar-brand img {
  height: 40px;
}
.nav-link {
  cursor: pointer;
}
.navbar-brand.brand-text {
  font-size: 20px;
  color: red;
  margin-left: 0px;
  margin-right: 10px;
}
.navbar-brand {
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.dropdown-item {
  cursor: pointer;
}
.bg-default {
  background-color: #02133E !important;
}
@media (max-width: 767.98px) {
  .navbar-brand {
    font-size: 14px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
