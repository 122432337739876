<template>
    <div style="height: 100vh;
      overflow-y: scroll;
      background-size: cover; padding-bottom: 15rem; background-attachment: fixed;" class="px-3 pt-3">
      <div style="height: 130vh;" v-if="nextLevel" >
        <div class="profile-container col-12 card" style="background: linear-gradient(to top, #0ba360 0%, #3cba92 100%); box-shadow: none;">
        <div class="up-cont d-flex py-3 pr-3">
            <div>
                <img src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/icons/melon-zeed.png" style="width: 92px; height: 92px; border-radius: 50%;" alt="">
            </div>
            <div class="col-7 pl-4 pt-4">
                <h3 class="text-white">{{$store.state.authUser.name}}</h3>
                <p style="color: #FEE19F">Level {{ $store.state.authUser.level }}</p>
            </div>
            <div>
                <img :src="`https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/levels/vip_${ $store.state.authUser.level }.png`" style="width: 60px; height: auto" alt="">
            </div>
        </div>
        <div class="down-cont pb-5">
            <h3 style="color: whitesmoke" class="my-4">{{$t('level.requirement')}}</h3>
            <div>
                <span style="color: #000">{{$t('level.current_bet')}} - </span> <span style="color: #FEE19F">{{Number($store.state.authUser.total_bet_amount).toLocaleString()}}</span>
                <div class="my-3">
                    <van-progress v-if="$store.state.authUser.total_bet_amount < nextLevel.target_bet" :pivot-text="`${(($store.state.authUser.total_bet_amount * 100) / nextLevel.target_bet).toFixed(2)}%`" color="#FEE19F" :percentage="($store.state.authUser.total_bet_amount * 100) / nextLevel.target_bet" />
                    <van-progress v-if="$store.state.authUser.total_bet_amount >= nextLevel.target_bet" pivot-text="100%" color="#FEE19F" percentage="100" />
                </div>
                <span style="color: #000">{{$t('level.required_bet')}} - </span> <span style="color: #FEE19F" v-if="$store.state.authUser.total_bet_amount < nextLevel.target_bet" >{{Number(nextLevel.target_bet - $store.state.authUser.total_bet_amount).toLocaleString()}}</span> <span v-else style="color: #FEE19F" >0</span>

                <!-- <hr style="border-color: #000 !important; border-bottom: none;"> -->
                 <div class="my-5">

                 </div>

                <span style="color: #000">{{$t('level.current_deposit')}} - </span> <span style="color: #FEE19F">{{Number($store.state.authUser.total_deposit).toLocaleString()}}</span>
                <div class="my-3">
                    <van-progress v-if="$store.state.authUser.total_deposit < nextLevel.target_deposit" :pivot-text="`${(($store.state.authUser.total_deposit * 100) / nextLevel.target_deposit).toFixed(2)}%`" color="#FEE19F" :percentage="($store.state.authUser.total_deposit * 100) / nextLevel.target_deposit" />
                    <van-progress v-if="$store.state.authUser.total_deposit >= nextLevel.target_deposit" pivot-text="100%" color="#FEE19F" percentage="100" />
                </div>
                <span style="color: #000">{{$t('level.required_deposit')}} - </span> <span style="color: #FEE19F" v-if="$store.state.authUser.total_deposit < nextLevel.target_deposit">{{ Number(nextLevel.target_deposit - $store.state.authUser.total_deposit).toLocaleString()}}</span> <span v-else style="color: #FEE19F">0</span>
            </div>
        </div>
      </div>
        <div class="vip-levels-container d-flex py-3">
            <div class="d-flex" v-for="(level, index) in levels" :key="index">   
                <div class="level" :class="(level.level <= currentLevel && level.is_collected) ? 'done' : (selectedLevel === level.level ? 'active' : '')" @click="(level.level <= currentLevel && level.is_collected) ? '' :updateLevel(level.level)">
                <span class="text-nowrap"><i>V{{level.level}}</i></span>
                </div>
                <div class="middleware my-auto"></div>
            </div>
        </div>
        <div class="profile-container col-12 card mt-3" style="background: linear-gradient(to top, #0ba360 0%, #3cba92 100%); box-shadow: none">
            <div class="up-cont d-flex justify-content-between">
                <div class="pt-2 pl-2">
                    <span style="font-size: 3rem; color: #FDDA75">VIP{{ objectSelectedLevel.level }}</span>
                </div>
                <div>
                    <img :src="`https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/levels/vip_${ selectedLevel }.png`" style="width: 60px; height: auto" alt="">
                </div>
            </div>
            <div class="down-cont d-flex pb-3">
                <div class="col-4 pl-4 pt-4">
                    <h2 class="text-white text-nowrap">{{ Number(objectSelectedLevel.target_bet).toLocaleString()}}</h2>
                    <p style="color: #FEE19F" class=" text-nowrap">{{$t('level.bet_amount')}}</p>
                </div>
                <div class="col-4 pl-4 pt-4">
                    <h2 class="text-white text-nowrap">{{ Number(objectSelectedLevel.target_deposit).toLocaleString() }}</h2>
                    <p style="color: #FEE19F" class=" text-nowrap">{{$t('level.deposit_amount')}}</p>
                </div>
                <div class="col-4 pl-4 pt-4">
                    <h2 class="text-white text-nowrap">{{ Number(objectSelectedLevel.bonus).toLocaleString() }} %</h2>
                    <p style="color: #FEE19F" class="text-nowrap">{{$t('level.loss_return')}}</p>
                </div>
            </div>
            <div class="reward mb-3 mx-auto" v-if="objectSelectedLevel.is_done && !objectSelectedLevel.is_collected">
                <button class="btn btn-customize" @click="isLoading ? '' : claimReward(objectSelectedLevel.level)">
                    <div v-if="!isLoading">
                        {{$t('level.claim_reward')}}
                    </div>
                    <Loading
                        color="#000000"
                        v-if="isLoading"
                        size="24"
                        class="px-4"
                    />
                </button>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
import { Toast, Loading } from 'vant';
import { mapActions } from 'vuex';

export default {
    components: {
        Loading
    },
    data() {
        return{
            objectSelectedLevel: {},
            currentLevel: 0,
            selectedLevel: 1,
            levels: [],
            isLoading: false
        }
    },
    computed: {
        nextLevel(){
            return this.levels.find(l => l.level === (this.$store.state.authUser.level + 1));
        }
    },
    methods: {
        ...mapActions(['fetchUser']),
        async claimReward(level){
            try {
                this.isLoading = true;
                const res = await axios.post('/user/claim-reward', { level }, {
                    headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                Toast.success("Success");
                await this.getLevels();
                await this.fetchUser();
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                Toast.fail("Failed");
            }
        },
        updateLevel(level){
            this.objectSelectedLevel = this.levels.find(l => l.level === level);
            this.selectedLevel = level;
        },
        async getLevels() {
            // get levels from api
            const res = await axios.get('/user/levels',  {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
            this.levels = res.data.data;
            this.objectSelectedLevel = this.levels.find(l => l.level === this.$store.state.authUser.level + 1);
            this.selectedLevel = this.$store.state.authUser.level + 1;
        }
    },
    async mounted() {
        // this.$store.commit('setLoading', true);
        const authUser = await this.fetchUser();
        await this.getLevels();
        this.currentLevel = this.$store.state.authUser.level
        // this.$store.commit('setLoading', false);
        if (!authUser) {
            this.$router.push('/auth/login');
        }
        // if(authUser && !authUser.is_verified) {
        //     this.$router.push('/auth/verify');
        // }
    }
}
</script>
<style scoped>
.vip-levels-container {
    overflow-y: scroll !important;
}

.btn-customize {
  background: #fedf78;
  color: #360000 !important;
  border-radius: 5px;
  font-size: 1.1rem;
  width: 150px;
}

.level{
    background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
    /* padding: 1rem; */
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 50%;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    /* border: 2px solid #840303; */
    color: white;
    font-size: 1.2rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border: 3px solid #FCC5A0;
}

.level.active{
    
    background: #FCC5A0;
    color: black;
    border: 3px solid #FCC5A0;
}

.level.done{
    background: red;
    color: transparent;
    background-image: url('/public/build/assets/img/icons/common/checked.png');
    background-size: cover;
    border: none;
}

.middleware {
    border: 1px solid #FEE19F;
    /* width: 2rem !important; */
    padding-left: 1rem;
    padding-right: 1rem;
    height: 0px;
}
</style>