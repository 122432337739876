<template>
  <div style="position: relative; height: 100vh !important;" class="col-sm-12 p-0 m-0 mx-auto" :class="$route.path === '/iframeURL' ? '' : 'col-lg-4'">
    <NavBar
      v-if="
        !$route.path.includes('rule') &&
        $route.path !== '/home' &&
        $route.path !== '/' &&
        $route.path !== '/auth/login' &&
        $route.path !== '/auth/register' &&
        $route.path !== '/iframeURL'
      "
    />
    <router-view
      :class="$route.path.includes('login') ? '' : 'middle-container'"
      style="position: relative; height: 100vh;"
    ></router-view>
    <Footer v-if="!$route.path.includes('rule') && !$route.path.includes('live-score')" />
    <!-- <div class="social-contacts" id="social-contacts">
            <div
                style="position: relative"
                class="cart-container"
                v-if="
                    $route.path.includes('maung-fb') ||
                    $route.path.includes('body-fb')
                "
                @click="openModalVoucher()"
            >
                <div>
                    <i class="ni ni-cart mb-2"></i>
                </div>
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-if="$route.path.includes('2d')"
                    >{{ this.$store.state.twod_slip_data.length }}</span
                >
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-if="$route.path.includes('fb')"
                    >{{ this.$store.state.fb_order.fb_matches.length }}</span
                >
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-else-if="$route.path.includes('3d')"
                    >{{ this.$store.state.threed_slip_data.length }}</span
                >
            </div>
        </div> -->

    <van-overlay :show="$store.state.isLoading">
      <div class="wrapper d-flex h-100vh" style="background: rgba(0, 0, 0, 0.8);">
        <div class="loader my-auto mx-auto">

        </div>
      </div>
    </van-overlay>

    <van-overlay :show="showPopup" @click="showPopup = false">
      <div class="wrapper col-lg-4 col-md-12 mx-auto d-flex h-100vh" @click.stop>
        <div
          class="mx-auto my-auto pt-4 px-4 pb-4"
          style="background: rgba(0, 0, 0, 0.8); border-radius: 7px;"
        >
          <!-- <img v-for="(welcome, index) in websiteInfo.welcome_banners" :key="index" :src="'https://yy24gld.sgp1.cdn.digitaloceanspaces.com/luckywin/banners/' + welcome.url" class="mx-auto my-auto" alt="" style="width: 100%; border-radius: 5px;"> -->
          <b-carousel
            id="carousel-1"
            :interval="3000"
            controls
            fade
            style="width: 100%; height: 100%"
          >
            <b-carousel-slide
              img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/pg-welcom.jpg"
            ></b-carousel-slide>
            <!-- <b-carousel-slide
              img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/hulk333/banners/welcome-1.jpg"
            ></b-carousel-slide> -->
          </b-carousel>
          
          <!-- <video style="border-radius: 5px;" class="d-flex" width="100%" height="auto" autoplay loop muted>
                  <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/0512ca93-7a5f-40cd-a989-8f5f3b21a600/public" type="video/mp4" />
                  Your browser does not support the video tag.
                </video> -->

          <div class="mt-3 d-flex flex-column">
            <div class="mx-auto mb-3">
              <div class="d-flex">
                <input
                  type="checkbox"
                  v-model="isHideForToday"
                  class="mb-1 mr-2 p-0 m-0"
                />
                <h3 class="text-white text-center p-0 m-0">Hide For Today</h3>
              </div>
            </div>

            <button
              class="btn btn-primary btn-block w-25 mx-auto text-uppercase text-dark"
              style="background: radial-gradient(circle at 10% 20%, rgb(241, 121, 226) 0%, #DD4DD1 86.8%) !important;"
              @click="closePopup()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- <div>
      <FloatIcon />
    </div> -->

    <van-overlay :show="isLuckyWheel">
      <div class="wrapper col-8" @click.stop>
        <LuckyWheel />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Dialog } from 'vant';
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import LuckyWheel from '../frontend/components/LuckyWheel.vue';

export default {
  components: {
    LuckyWheel,
  },
  data() {
    return {
      isLuckyWheel: false,
      isHideForToday: false,
      showPopup: false,
      websiteInfo: {},
    };
  },
  async mounted() {
    // this.$store.commit('setLoading', true);
    this.checkPopupStatus();
    await this.fetchWebsiteData();
    // this.$store.commit('setLoading', false);
  },
  computed: {
    ...mapGetters(['getFBOrder']),
  },

  methods: {
    testing() {
      this.isLuckyWheel = true;
      console.log('testing');
    },
    closePopup() {
      this.showPopup = false;
      const today = new Date().toISOString().split('T')[0];
      if (this.isHideForToday)
        localStorage.setItem('popupDismissedDate', today);
    },
    checkPopupStatus() {
      const today = new Date().toISOString().split('T')[0];
      const lastDismissedDate = localStorage.getItem('popupDismissedDate');
      if (lastDismissedDate !== today) {
        this.showPopup = true;
      }
    },

    ...mapMutations(['setWholeFBMatches']),
    ...mapActions(['fetchUser']),
    closeModalOutside(event) {
      if (event.target.classList.contains('modal')) {
        this.$router.go();
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data[0];
        if (
          res.data.data[0].androidVersion !== this.$store.state.androidVersion
        ) {
          $('#version').modal('show');
        }
      } catch (error) {
        console.log(error);
      }
    },
    openModalVoucher() {
      if (this.$store.state.fb_order.fb_matches.length === 0) {
        const dataError =
          this.$store.state.language === 'en'
            ? 'Please select some teams!'
            : 'အသင်းတစ်ချို့ ရွေးပေးပါ။';
        this.dialog(dataError);
      } else {
        const matches = this.getFBOrder.fb_matches;
        if (!this.$store.state.betAmount) {
          $('#amount').modal('show');
        } else {
          for (let i = 0; i < matches.length; i += 1) {
            matches[i].bet_amount = this.$store.state.betAmount;
          }
          this.setWholeFBMatches(matches);
          $('#mobile-voucher').modal('show');
        }
      }
    },

    dialog(text) {
      Dialog.alert({
        message: text,
        confirmButtonText:
          this.$store.state.language === 'en'
            ? 'Go Back'
            : 'နောက်သို့ပြန်သွားမည်။',
      }).then(() => {
        // on close
      });
    },
  },
};
</script>
<style scoped>
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader::before {
  border-color: #f03355 #0000;
  animation: inherit; 
  animation-duration: .5s;
  animation-direction: reverse;
}
.loader::after {
  margin: 8px;
}
@keyframes l16 { 
  100%{transform: rotate(1turn)}
}


.active-contact {
  transform: translate(0);
  transition: all 1s ease-in-out;
}
.box-contact .open-contact {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -12px;
  margin: auto;
}
.box-contact a img {
  width: 40px;
}
.box-contact {
  position: fixed;
  top: 60%;
  right: 0;
  border-radius: 12px 0 0 12px !important;
  background: linear-gradient(
    180deg,
    rgba(62, 62, 62, 0.5411764706) 0%,
    rgba(10, 10, 10, 0.5725490196) 100%
  );
  padding: 1rem 0.5rem 1rem 1.3rem;
  transform: translate(0);
  animation: slide 2s ease-in;
  z-index: 10;
}
.van-overlay {
  background: rgba(0, 0, 0, 0.4) !important;
}
.floating {
  position: fixed;
  width: 90px;
  z-index: 1;
  animation: floating 20s infinite;
  bottom: 130px;
  right: 0%;
}
#closeButton {
  border: none;
  background: none;
  position: absolute;
  left: -15px;
  font-size: 20px;
  font-weight: bold;
  color: #b7b7b7;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatey(0px);
  }
}

.cart-number {
  top: -2px !important;
  left: -2px !important;
}
.cart-number {
  position: absolute;
  top: -3px;
  left: -3px;
  text-align: center;
}
.middle-container {
  min-height: 75vh;
}
@media (max-width: 768px) {
  .cart-container {
    font-size: 30px !important;
  }
  .social-contacts div {
    width: 60px !important;
    height: 60px !important;
    margin-top: 15px;
  }
  #social-contacts .badge-circle.badge-md {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
  .cart-container {
    visibility: visible;
    font-size: 16px;
  }
  .middle-container {
    min-height: 100vh;
    margin: 0px !important;
    /* padding-top: 0.1rem !important; */
    /* margin-bottom: 50px !important; */
  }
}
</style>
