var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid d-flex flex-wrap main-wrapper",staticStyle:{"background":"radial-gradient(circle, #7b0e7f 0%, #431044 90%)","height":"100vh","overflow-y":"scroll","background-size":"cover","padding-bottom":"15rem !important","background-attachment":"fixed"}},[_c('div',{staticClass:"col-12 p-0 mb-5"},[_c('div',{staticClass:"bg-transparent"},[_c('div',{staticClass:"pl-2 pt-3 bg-transparent"},[_c('div',{staticClass:"align-items-center"},[_c('div',{staticClass:"col-8"},[_c('h3',{staticClass:"mb-0",staticStyle:{"color":"#fff !important"}},[_vm._v(" "+_vm._s(_vm.$t('banking.bank_info'))+" ")])])])]),(_vm.error)?_c('div',{staticClass:"error text-center py-2"},[_c('span',{staticClass:"mx-auto"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]):_vm._e(),_c('div',{staticClass:"card-body bg-transparent"},[_c('form',[_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",staticStyle:{"color":"#fff !important","font-size":"1rem","font-weight":"400 !important"},attrs:{"for":"input-email"}},[_vm._v(_vm._s(_vm.$t('banking.bank_username')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.account.name
                                        ),expression:"\n                                            account.name\n                                        "}],staticClass:"form-control custom-input",attrs:{"type":"text"},domProps:{"value":(
                                            _vm.account.name
                                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.account, "name", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.name))])])]),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",staticStyle:{"color":"#fff !important","font-size":"1rem","font-weight":"400 !important"},attrs:{"for":"input-email"}},[_vm._v(_vm._s(_vm.$t('banking.bank_number')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.account.account_number
                                        ),expression:"\n                                            account.account_number\n                                        "}],staticClass:"form-control custom-input",attrs:{"type":"number"},domProps:{"value":(
                                            _vm.account.account_number
                                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.account, "account_number", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number))])])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",staticStyle:{"color":"#fff !important","font-size":"1rem","font-weight":"400 !important"},attrs:{"for":"input-username"}},[_vm._v(_vm._s(_vm.$t('banking.bank_type')))]),_c('AccountSelect',{attrs:{"data":_vm.account,"field":"type","accounts":_vm.accounts}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.account))])],1)])])]),_c('div',{staticClass:"form-group col-12 mt-2"},[_c('button',{staticClass:"btn btn-primary custom-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.updateBankInfo()}}},[(!_vm.isConfirm)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('banking.submit'))+" ")]):_vm._e(),(_vm.isConfirm)?_c('Loading',{staticClass:"px-4 py-0",attrs:{"color":"#ffffff","size":"24"}}):_vm._e()],1)]),_c('div',{staticClass:"col-12"},[_c('h3',{staticStyle:{"color":"#ccc","font-size":"1rem","font-weight":"400 !important"}},[_vm._v("** "+_vm._s(_vm.$t('banking.note'))+" **")]),_c('span',{staticStyle:{"color":"#ccc","font-size":"0.9rem"}},[_vm._v(" "+_vm._s(_vm.$t("banking.note_content"))+" ")])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }